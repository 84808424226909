<template>
  <section class="app-ecommerce-details">
    <!-- Content -->
    <Loader v-if="loading" />
    <div v-else>
      <b-card no-body>
        <b-card-body>
          <b-row class="d-flex align-items-center">
            <b-col cols="3">
              <b-card-text>Delivery Type</b-card-text>
              <b-form-select
                v-model="selectedDeliveryTypes"
                :options="deliveryTypes"
                @change="updateDeliveryTypes"
              ></b-form-select>
            </b-col>
            <b-col cols="3">
              <b-card-text>Order Status</b-card-text>
              <b-form-select
                v-model="selectedOrderID"
                :options="orderStatus"
                @change="updateOrderStatus"
              >
              </b-form-select>
            </b-col>
            <b-col cols="3">
              <b-card-text>Tracking Number</b-card-text>
              <div class="trackingStyle">
                <b-card-text>{{ orderItem.trackingNumber }}</b-card-text>
              </div>
            </b-col>
            <b-col style="padding-top: 2.5rem">
              <b-button variant="primary" @click="handleUpdateTrackingNumber">
                Update Tracking Number</b-button
              >
            </b-col>
          </b-row>
          <b-row class="my-2">
            <b-col cols="12" md="7" lg="12">
              <h4>Order Information</h4>
              <div class="ecommerce-details-price d-flex flex-wrap mt-1">
                <h4 class="item-price mr-1">
                  <span class="text-primary"></span>
                </h4>
              </div>
              <!-- Avability -->
              <b-row>
                <b-col cols="4">
                  <b-card-text class="item-company mb-0">
                    <span>Cart Total:</span>
                    <b-link class="company-name">
                      {{ orderItem.cartTotal }}
                    </b-link>
                  </b-card-text>
                </b-col>
                <b-col cols="4">
                  <b-card-text class="item-company mb-0">
                    <span>Order Number:</span>
                    <b-link class="company-name">
                      {{
                        orderItem.orderNumber
                      }}
                    </b-link>
                  </b-card-text>
                </b-col>
                <b-col cols="4"
                  ><b-card-text class="item-company mb-0">
                    <b-card-text
                      >Order Date:
                      <span class="text-primary">{{
                        orderItem ? returnPaymentDate(this.orderItem.cartConvertDate) : "Null"
                      }}</span></b-card-text
                    >
                  </b-card-text>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col cols="12">
                  <h4>Shipping Information</h4>
                  <div class="ecommerce-details-price d-flex flex-wrap mt-1">
                    <h4 class="item-price mr-1">
                      <span class="text-primary"></span>
                    </h4>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-card-text>
                    <span style="align-items: center; font-size: 16.5px">
                      <b>Shipping Person </b>

                      <feather-icon
                        v-show="!editable"
                        @click="toggleEditable"
                        class="ml-2"
                        icon="EditIcon"
                        size="18"
                      />
                    </span>
                  </b-card-text>
                  
                  <b-card-text v-if="!editable">
                    <span>
                      <b style="font-size: 14px">Name: </b>
                      {{
                        orderItem.shippingPerson
                          ? JSON.parse(orderItem.shippingPerson).name
                          : null
                      }}
                    </span>
                  </b-card-text>
                  <b-col cols="12" v-else>
                    <b-row class="align-items-center">
                      <label class="labelStyle">Name:</label>
                      <span>
                        <b-form-input v-model="shippingPerson.name" />
                      </span>
                    </b-row>
                  </b-col>
                  <b-card-text v-if="!editable">
                    <span>
                      <b style="font-size: 14px">Company Name: </b>
                      {{
                        orderItem.shippingPerson
                          ? JSON.parse(orderItem.shippingPerson).companyName
                          : null
                      }}
                    </span>
                  </b-card-text>
                  <b-col cols="12" v-else>
                    <b-row class="align-items-center mt-1">
                      <label class="labelStyle">Company Name:</label>
                      <span>
                        <!-- <b style="font-size: 14px;">Street: </b>  -->
                        <b-form-input v-model="shippingPerson.companyName" />

                        <!-- {{
                   orderItem.shippingPerson ?  JSON.parse(orderItem.shippingPerson).street : null
                  }}  -->
                      </span>
                    </b-row>
                  </b-col>
                  <b-card-text v-if="!editable">
                    <span>
                      <b style="font-size: 14px">Street: </b>
                      {{
                        orderItem.shippingPerson
                          ? JSON.parse(orderItem.shippingPerson).street
                          : null
                      }}
                    </span>
                  </b-card-text>
                  <b-col cols="12" v-else>
                    <b-row class="align-items-center mt-1">
                      <label class="labelStyle">Street:</label>
                      <span>
                        <!-- <b style="font-size: 14px;">Street: </b>  -->
                        <b-form-input v-model="shippingPerson.street" />

                        <!-- {{
                   orderItem.shippingPerson ?  JSON.parse(orderItem.shippingPerson).street : null
                  }}  -->
                      </span>
                    </b-row>
                  </b-col>
                  <b-card-text v-if="!editable">
                    <span>
                      <b style="font-size: 14px">Country: </b>
                      {{
                        orderItem.shippingPerson
                          ? JSON.parse(orderItem.shippingPerson).countryName
                          : null
                      }}
                    </span>
                  </b-card-text>
                  <div
                    v-else
                    style="align-items: baseline;
                      flex-direction: row;
                      display: flex;
                      margin-top: 1rem;
                    "
                  >
                    <label class="labelStyle">Country: </label>
                    <span style="min-width: 13.5rem">
                      <selector
                        :disabled="!editable"
                        @selectionChanged="handleCountry"
                        :selected="selectedCountry"
                        :options="countries"
                      />

                      <!-- {{
                   orderItem.shippingPerson ?  JSON.parse(orderItem.shippingPerson).street : null
                  }}  -->
                    </span>
                  </div>
                  <b-card-text v-if="!editable">
                    <span>
                      <b style="font-size: 14px">State: </b>
                      {{
                        orderItem.shippingPerson
                          ? JSON.parse(orderItem.shippingPerson)
                              .stateOrProvinceName
                          : null
                      }}
                    </span>
                  </b-card-text>
                  <div
                    v-else
                    style="align-items: baseline;
                      flex-direction: row;
                      display: flex;
                    "
                  >
                    <label class="labelStyle">State: </label>
                    <span style="min-width: 13.5rem">
                      <selector
                        :disabled="!editable"
                        @selectionChanged="handleProvince"
                        :selected="selectedProvince"
                        :options="provinces"
                      />
                      <!-- {{
                   orderItem.shippingPerson ?  JSON.parse(orderItem.shippingPerson).street : null
                  }}  -->
                    </span>
                  </div>
                  <b-card-text v-if="!editable">
                    <span>
                      <b style="font-size: 14px">City: </b>
                      {{
                        orderItem.shippingPerson
                          ? JSON.parse(orderItem.shippingPerson).city
                          : null
                      }}
                    </span>
                  </b-card-text>
                  <div
                    v-else
                    style="
                      align-items: baseline;
                      flex-direction: row;
                      display: flex;
                    "
                  >
                    <!-- <b-card-text style=""> -->
                    <label class="labelStyle">City: </label>
                    <span style="min-width: 13.5rem">
                      <selector
                        :disabled="!editable"
                        @selectionChanged="handleCity"
                        :selected="selectedCity"
                        :options="cities"
                      />
                      <!-- {{
                   orderItem.shippingPerson ?  JSON.parse(orderItem.shippingPerson).street : null
                  }}  -->
                    </span>
                  </div>
                  <b-col cols="12">
                    <b-row class="justify-content-end">
                      <b-button
                        v-show="editable"
                        @click="updateShipping"
                        variant="primary"
                      >
                        Update
                      </b-button>
                      <b-button
                        @click="toggleEditable"
                        variant="outline-secondary"
                        v-show="editable"
                        class="ml-2"
                      >
                        Cancel
                      </b-button>
                    </b-row>
                  </b-col>
                </b-col>
              </b-row>

              <!-- <b-row class="mt-1">
                <b-col cols="12">
                  <b-card-text>
                    <span>
                      <b>Shipping Address: </b>
                      {{
                        orderItem.shipmentInfo
                          ? JSON.parse(orderItem.shipmentInfo).shippingAddress
                          : null
                      }}
                    </span>
                  </b-card-text>
                </b-col>
              </b-row> -->
              <b-row class="mt-2">
                <b-col cols="12">
                  <h4>Payment Information</h4>
                  <div class="ecommerce-details-price d-flex flex-wrap mt-1">
                    <h4 class="item-price mr-1">
                      <span class="text-primary"></span>
                    </h4>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-card-text>
                    <span style="align-items: center; font-size: 16.5px">
                      <b>Payment Details </b>

                      <feather-icon
                        v-show="!editPaymentInfo"
                        @click="toggleEditablePayment"
                        class="ml-2"
                        icon="EditIcon"
                        size="18"
                      />
                    </span>
                  </b-card-text>
                  <field
                    v-show="state.selectedPaymentMethod.value  == 'BANKTRANSFER'"
                    :name="'bankName'"
                    @handleChange="handlePaymentInformation"
                    :title="'Bank Name'"
                    :value="state.paymentInformation.bankName"
                    :editable="editPaymentInfo"
                  />
                  <field
                    :name="'paymentDate'"
                    :dateValue="
                      state.paymentInformation.paymentDate
                        ? returnPaymentDate(
                            state.paymentInformation.paymentDate
                          )
                        : null
                    "
                    @handleChange="handlePaymentInformation"
                    :title="'Payment Date'"
                    :value="state.paymentInformation.paymentDate"
                    :editable="editPaymentInfo"
                  />
                  <field
                    v-show="
                     state.selectedPaymentMethod.value  == 'BANKTRANSFER' ||
                     state.selectedPaymentMethod.value  == 'CREDITCARD'
                    "
                    :name="'transactionCode'"
                    @handleChange="handlePaymentInformation"
                    :title="'Transaction Code'"
                    :value="state.paymentInformation.transactionCode"
                    :editable="editPaymentInfo"
                  />
                  <field
                    v-show="state.selectedPaymentMethod.value  == 'CREDITCARD'"
                    :name="'paymentGateway'"
                    @handleChange="handlePaymentInformation"
                    :title="'Payment Gateway'"
                    :value="state.paymentInformation.paymentGateway"
                    :editable="editPaymentInfo"
                  />
                  <field
                    :name="'paymentName'"
                    @handleChange="handlePaymentInformation"
                    :title="'Payment Name'"
                    :value="state.paymentInformation.paymentName"
                    :editable="editPaymentInfo"
                  />
                  <b-card-text v-if="!editPaymentInfo">
                    <span>
                      <b style="font-size: 14px">Payment Status: </b>
                      {{
                        state.selectedPaymentStatus
                          ? state.selectedPaymentStatus.name
                          : null
                      }}
                    </span>
                  </b-card-text>
                  <div
                    v-else
                    style="
                      align-items: baseline;
                      flex-direction: row;
                      display: flex;
                      margin-top: 1rem;
                    "
                  >
                    <label class="labelStyle">Payment Status: </label>
                    <span style="min-width: 13.5rem">
                      <selector
                        :disabled="!editPaymentInfo"
                        @selectionChanged="handlePaymentStatus"
                        :selected="state.selectedPaymentStatus"
                        :options="state.paymentStatus"
                      />
                    </span>
                  </div>
                  <b-card-text v-if="!editPaymentInfo">
                    <span>
                      <b style="font-size: 14px">Payment Method: </b>
                      {{
                        state.selectedPaymentMethod
                          ? state.selectedPaymentMethod.name
                          : null
                      }}
                    </span>
                  </b-card-text>
                  <div
                    v-else
                    style="align-items: baseline;
                      flex-direction: row;
                      display: flex;
                      margin-top: 1rem;"
                  >
                    <label class="labelStyle">Payment Method: </label>
                    <span style="min-width: 13.5rem">
                      <selector
                        :disabled="!editPaymentInfo"
                        @selectionChanged="handlePaymentMethod"
                        :selected="state.selectedPaymentMethod"
                        :options="PAYMENT_METHODS"
                      />
                    </span>
                  </div>
                  <b-col cols="12">
                    <b-row class="justify-content-end">
                      <b-button
                        v-show="editPaymentInfo"
                        @click="
                          () => udpatePaymentInformation(this.$route.params.id)
                        "
                        variant="primary"
                      >
                        Update
                      </b-button>
                      <b-button
                        @click="toggleEditablePayment"
                        variant="outline-secondary"
                        v-show="editPaymentInfo"
                        class="ml-2"
                      >
                        Cancel
                      </b-button>
                    </b-row>
                  </b-col>
                </b-col>
              </b-row>
              <div class="ecommerce-details-price d-flex flex-wrap mt-2">
                <h4 class="item-price mr-1">Order Products</h4>
              </div>

              <b-table
                style="text-align: center"
                hover
                :items="products"
                :fields="productFields"
                @row-clicked="() => console.log('Clicked')"
                showEmpty
              >
                <template #cell(imageUrl)="data">
                  <img
                    style="height: 80px; width: 80px; align-self: center"
                    :src="data.item.imageUrl"
                  />
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </div>
  </section>
</template>
<script>
import { getCall, putCall } from "../Service";
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BTable,
  BImg,
  BCardText,
  BFormInput,
  BLink,
  BButton,
  BDropdown,
  BFormDatepicker,
  BDropdownItem,
  BAlert,
  BFormSelect,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Loader from "../components/Loader.vue";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import Swal from "sweetalert2";
import useOrderDetail from "./useOrderDetail";
import Selector from "../components/drop-down-selector/selector.vue";
import Field from "./components/Field.vue";
export default {
  directives: {
    Ripple,
  },
  data() {
    return {
      orderItem: [],
      products: [],
      // selectedCountry: null,
      editPaymentInfo: false,
      editable: false,
      selectedProvince: null,
      selectedCity: null,
      countries: [],
      provinces: [],
      cities: [],
      productId: null,
      productFields: ["imageUrl", "sku", "price", "productTitle", "quantity"],
      loading: false,
      orderStatus: [],
      selectedOrderID: null,
      selectedDeliveryTypes: null,
      deliveryTypes: [],
    };
  },
  components: {
    BCard,
    BCardBody,
    BRow,
    BFormDatepicker,
    BCol,
    BImg,
    BCardText,
    Field,
    BTable,
    BLink,
    BButton,
    BDropdown,
    BFormInput,
    BDropdownItem,
    BAlert,
    Loader,
    BFormSelect,
    Selector,
  },
  setup() {
    const {
      getCountries,
      PAYMENT_METHODS,
      getProvince,
      getCities,
      state,
      handlePaymentInformation,
      handlePaymentMethod,
      getAllPaymentStatus,
      udpatePaymentInformation,
      returnPaymentDate,
      handlePaymentStatus,
    } = useOrderDetail();
    const { shippingPerson, selectedCountry } = state;

    return {
      getCountries,
      handlePaymentMethod,
      getProvince,
      shippingPerson,
      state,
      PAYMENT_METHODS,
      getAllPaymentStatus,
      returnPaymentDate,
      handlePaymentInformation,
      handlePaymentStatus,
      udpatePaymentInformation,
      selectedCountry,
      getCities,
    };
  },
  watch: {
    orderItem: function () {
      if (
        this.state.paymentStatus &&
        this.state.paymentStatus.length &&
        this.orderItem
      ) {
        this.state.selectedPaymentStatus = this.state.paymentStatus.find(
          (i) => i.statusCode == this.orderItem.paymentStatus
        );
      }
      if (this.orderItem) {
        this.state.selectedPaymentMethod = this.PAYMENT_METHODS.find(i=>i.value == this.orderItem.paymentType);
        this.state.paymentInformation.bankName = this.orderItem.bankName;
        this.state.paymentInformation.transactionCode =
          this.orderItem.transactionCode;
        this.state.paymentInformation.paymentName = this.orderItem.paymentName;
        this.state.paymentInformation.paymentDate = this.orderItem.paymentDate;
        this.state.paymentInformation.paymentGateway =
          this.orderItem.paymentGateway;
      }
      if (this.orderItem && JSON.parse(this.orderItem.shippingPerson)) {
        this.shippingPerson = { ...JSON.parse(this.orderItem.shippingPerson) };
        if (this.countries && this.countries.length) {
          this.selectedCountry = this.countries.find(
            (i) =>
              i.iso2 == JSON.parse(this.orderItem.shippingPerson).countryCode
          );
        }
        if (this.selectedCountry) {
          this.shippingPerson.countryName = this.selectedCountry.name;
          this.shippingPerson.countryCode = this.selectedCountry.iso2;
        } else {
          this.shippingPerson.street = "";
          this.shippingPerson.countryCode = "";
          this.shippingPerson.countryName = "";
          this.shippingPerson.stateOrProvinceName = "";
          this.shippingPerson.stateOrProvinceCode = "";
          this.shippingPerson.city = "";
        }
      }
    },
    selectedCountry: async function (newVal, oldVal) {
      if (this.selectedCountry) {
        this.selectedProvince = null;
        this.selectedCity = null;
        this.provinces = await this.getProvince(this.selectedCountry.id);
        this.shippingPerson.countryName = this.selectedCountry.name;
        this.shippingPerson.countryCode = this.selectedCountry.iso2;
        this.selectedProvince = this.provinces.find(
          (i) =>
            i.name ==
            JSON.parse(this.orderItem.shippingPerson).stateOrProvinceName
        );
        this.shippingPerson.stateOrProvinceName = this.selectedProvince.name;
        this.shippingPerson.stateOrProvinceCode = this.selectedProvince.iso2;
      } else {
        this.shippingPerson.street = "";
        this.selectedProvince = null;
        this.selectedCity = null;
        this.shippingPerson.stateOrProvinceName = "";
        this.shippingPerson.stateOrProvinceCode = "";
        this.shippingPerson.city = "";
      }
    },
    selectedProvince: async function (newVal) {
      if (this.selectedProvince) {
        this.selectedCity = null;
        this.shippingPerson.stateOrProvinceName = this.selectedProvince.name;
        this.shippingPerson.stateOrProvinceCode = this.selectedProvince.iso2;
        this.cities = await this.getCities(this.selectedProvince.id);
        this.selectedCity = this.cities.find(
          (i) => i.name == JSON.parse(this.orderItem.shippingPerson).city
        );
      } else {
        this.selectedCity = null;
        this.shippingPerson.street = "";
      }
    },
    selectedCity: async function (newVal) {
      if (this.selectedCity) {
        this.shippingPerson.city = this.selectedCity.name;
      } else {
        this.shippingPerson.street = "";
      }
    },
    countries: async function (newVal) {
      if (
        this.countries &&
        this.orderItem &&
        JSON.parse(this.orderItem.shippingPerson)
      ) {
        this.selectedCountry = this.countries.find(
          (i) => i.iso2 == JSON.parse(this.orderItem.shippingPerson).countryCode
        );
      }
    },
  },
  methods: {
    async fetchOrderDetails() {
      this.loading = true;

      try {
        // this.countries = await this.getCountries();
        // this.provinces = await this.getProvince()
        const { data } = await getCall(
          `admin/store/order/${this.$route.params.id}`
        );
        this.orderItem = JSON.parse(JSON.stringify(data.orderItem));
        this.products = data.itemsInOrder;
      } catch (error) {
        if (error.response) {
          Swal.fire("Error:", error.response.data.message, "error");
        } else {
          Swal.fire("Error:", error, "error");
        }
      } finally {
        this.loading = false;
      }
    },

    updateShipping() {
      putCall(
        `admin/store/order/update-order-shipping-person/${this.$route.params.id}`,
        {
          shippingPerson: {
            ...this.shippingPerson,
          },
        }
      ).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Shipping Updated Successfully`,
            icon: "CoffeeIcon",
            variant: "success",
          },
        });
      });
    },
    toggleEditablePayment() {
      this.editPaymentInfo = !this.editPaymentInfo;
    },
    toggleEditable() {
      this.editable = !this.editable;
    },
    handleCity(item) {
      this.selectedCity = item;
    },
    handleCountry(item) {
      this.selectedCountry = item;
    },
    handleProvince(item) {
      this.selectedProvince = item;
    },

    async getOrderStatus() {
      try {
        const { data } = await getCall("admin/store/order/status");
        const orderStatusResponse = data.orderStatus.map((el) => {
          return {
            value: el.StatusCode,
            text: el.title,
          };
        });
        this.orderStatus = orderStatusResponse;
        const defaultOrderStatus = orderStatusResponse.filter(
          (e) => e.value == this.orderItem.orderStatus
        );
        this.selectedOrderID = defaultOrderStatus[0].value;
      } catch (error) {}
    },
    async updateOrderStatus() {
      this.loading = true;
      try {
        await putCall(
          `admin/store/order/update-order-status/${this.orderItem.id}`,
          {
            status: this.selectedOrderID,
          }
        );
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Order Status Updated Successfully`,
            icon: "CoffeeIcon",
            variant: "success",
          },
        });
      } catch (error) {
        if (error.response) {
          Swal.fire("Error:", error.response.data.message, "error");
        } else {
          Swal.fire("Error:", error, "error");
        }
      } finally {
        this.loading = false;
      }
    },
    async getDeliveryTypes() {
      try {
        const { data } = await getCall("admin/store/delivery/types");
        const defaultDeliveryType = data.deliveryTypes.map((el) => {
          return {
            value: el.id,
            text: el.title,
          };
        });
        this.deliveryTypes = defaultDeliveryType;
        const getDeliveryType = defaultDeliveryType.filter((el) => {
          if (el.value === this.orderItem.deliveryType) {
            return el.value;
          }
        });
        this.selectedDeliveryTypes = getDeliveryType[0].value;
      } catch (error) {}
    },
    async updateDeliveryTypes() {
      this.loading = true;
      try {
        return await putCall(
          `admin/store/order/update-order-delivery-type/${this.orderItem.id}`,
          { type: this.selectedDeliveryTypes }
        );
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    handleUpdateTrackingNumber() {
      Swal.fire({
        title: "Update Tracking Number",
        input: "text",
        inputValue: this.orderItem.trackingNumber,
        showCancelButton: true,
        buttonsStyling: {
          confirmButton: "margin-right:12px;",
        },
        confirmButtonText: "Update",
        showLoaderOnConfirm: true,
        preConfirm: async (text) => {
          this.loading = true;
          try {
            await putCall(
              `admin/store/order/update-tracking-number/${this.orderItem.id}`,
              {
                trackingNumber: `${text}`,
              }
            );
            this.fetchOrderDetails();
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Product Updated Successfully`,
                icon: "CoffeeIcon",
                variant: "success",
              },
            });
          } catch (error) {
            console.log("Error:", error);
          } finally {
            this.loading = false;
          }
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          this.orderItem.trackingNumber =
            result.value.data.order.trackingNumber;
        }
      });
    },
  },

  async mounted() {
    await this.getAllPaymentStatus()
    await this.fetchOrderDetails();
    await this.getOrderStatus();
    await this.getDeliveryTypes();
    setTimeout(async () => {
      // this.loading  = true
      this.countries = await this.getCountries();
      // this.loading  = false
    }, 300);
  },
};
</script>
<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";

.swal2-confirm {
  margin-right: 10px !important;
}
.labelStyle {
  font-size: 14px;
  margin-right: 10px;
  font-weight: 600;
  color: #6e6b7b;
  width: 9rem;
}
.trackingStyle {
  height: 2.5rem;
  display: flex;
  align-items: center;
  border: 1px solid rgb(223, 223, 223);
  border-radius: 5px;
  padding: 0 1rem;
}
</style>
