<template>
    <b-row>
      <b-col cols="12">
        <b-form-group >
          <v-select
            v-if="options != null"
            :disabled="disabled"
            v-model="selected"
            
            chips
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :options="options"
            class="select-size-lg"
            filled
            style="flex: 1;min-width: 150px;"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </template>
    
    <script>
  import { BRow, BCol, BFormGroup } from "bootstrap-vue";
  import vSelect from "vue-select";
  import store from "@/store";
  import Ripple from "vue-ripple-directive";
  import "vue-select/dist/vue-select.css";
  
  // import useUsersList from '../courseCategoryStoreModule';
  import axios from "@axios";
  // import api, { getCall } from "@/config";
  export default {
    components: {
      BRow,
      BCol,
      BFormGroup,
      vSelect,
    },
    directives: {
      Ripple,
    },
  
    props: {
      initialCategories: [],
      categories: [],
      selected: null,
      options:[],
      disabled:{
        required:false,
        type:Boolean
      }
    },
    updated() {
      //console.log('sele', this.options);
      // let temp = this.selected
      // const res = temp.map(i=>i.id)
      // //console.log('ressssTTT',res);
    },
    methods: {
      updateSelections(a) {
        //console.log(a);
      },
  
      // },
    },
    watch: {
      options:function(){
        //console.log('object',this.options);
      },
      selected: function (newVal, coldVal) {
        //console.log(this.initialCategories);
        //console.log(this.selected);
        //console.log("added", newVal);
        // const temp = newVal.map((i) => i.id);
        
        this.$emit("selectionChanged", newVal);
      },
    },
  
    data() {
      return {
        categories: null,
        option: [
          { name: "Square", value: "square", adddress: "259 Tariq Block" },
          //   { title: 'Rectangle' },
          //   { title: 'Rombo' },
          //   { title: 'Romboid' },
        ],
      };
    },
  };
  </script>
  
    <style>
  .vs__dropdown-toggle {
    height: 40px;
    border-radius: 6px;
    border-color: #dddddd;
  }
  </style>
    