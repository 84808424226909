import axios from "@axios";
import { onUpdated, reactive, watchEffect,watch,ref, onMounted } from "@vue/composition-api";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import { putCall } from "../Service";
import moment from "moment";


export default function useOrderDetail(){

    const PAYMENT_METHODS = [
      {name:'CREDITCARD',value:'CREDITCARD'},
      {name:'ONDOORPAY',value:'ONDOORPAY'},
      {name:'BANKTRANSFER',value:'BANKTRANSFER'},
    ]

    const state = reactive({
        selectedCity:null,
        selectedCountry:null,
        countries: [],
        provinces:[],
        selectedProvince:null,
        selectedPaymentStatus:null,
        selectedPaymentMethod:null,
        paymentStatus:[],
        paymentInformation:{
        paymentDate: "",
        paymentName: "",
        bankName: "",
        transactionCode: "",
        paymentGateway: ""
        },
        shippingPerson: {
            city: "",
            companyName: "",
            countryCode: "",
            countryName: "",
            name: "",
            phone: "",
            postalCode: "",
            stateOrProvinceCode: "",
            stateOrProvinceName: "",
            street: "",
          },
    })

    function handlePaymentInformation (name,text){
      //console.log('hello',name,text);
      state.paymentInformation = {...state.paymentInformation,[name]:text}
    }
    function handleShippingPerson (name,text){
      //console.log('hello',name,text);
      state.shippingPerson = {...state.shippingPerson,[name]:text}
    }

    watch(state,()=>{
      // console.log('state',state)
    },{deep:true});

   watchEffect(async ()=>{
        //console.log('sssaaaa',state.paymentInformation);
        // if(state.selectedCountry){
        //     getProvince(state.selectedCountry.id).then(i=>{
        //         //console.log('i',i);
        //         state.provinces = i
        //     })
        // }
    })
    

    function handlePaymentMethod(item) {
      //console.log(item);
      state.selectedPaymentMethod = item;
    }
    function handlePaymentStatus(item) {
      //console.log(item);
      state.selectedPaymentStatus = item;
    }

   function handleCity(item) {
        state.selectedCity = item;
      }
     async function handleCountry(item) {
        state.selectedCountry = item;
        state.provinces = await getProvince(state.selectedCountry.id)
        //console.log('state.provinces',state.provinces);
      }
      function handleProvince(item) {
        state.selectedProvince = item;
      }
    async function getCountries(){
      var host=process.env.VUE_APP_API_URL;

        const urlPrefix = host;
        const  {data}  = await axios.get(`/countries`);
        state.countries = data 
        //console.log('dataCoutnr',data);
        return data
    }
    async function getProvince(id){
      var host=process.env.VUE_APP_API_URL;

      const urlPrefix = host;
        const { data } = await axios.get(`/states?countryid=${id}`);
        return data
    }
    async function getCities(id){
      var host=process.env.VUE_APP_API_URL;

      const urlPrefix = host;
        const { data } = await axios.get(`/cities?state=${id}`);
        return data
    }

    async function getAllPaymentStatus(){
        const {data} = await axios.get('/admin/store/order/payment-status/all')
        state.paymentStatus = data.paymentStatus
        state.paymentStatus.forEach(i=>{
            i.name = i.title
        })
        return data
    }
    async function getAllPaymentMethods(){
      const {data} = await axios.get('/admin/store/order/payment-status/all')
      state.paymentStatus = data.paymentStatus
      state.paymentStatus.forEach(i=>{
          i.name = i.title
      })
      return data
  }

    function returnPaymentDate(date){
        return moment(date).format('DD-MM-YYYY')
        
    }


  //  onMounted(()=>{
  //   getAllPaymentStatus()
  //  })
    

    async function udpatePaymentInformation(id) {
      putCall(
        `admin/store/order/update-payment-status/${id}`,
          {
          paymentStatus:state.selectedPaymentStatus.statusCode
          }
      )
      putCall(
        `admin/store/order/update-payment-types/${id}`,
          {
          paymentType:state.selectedPaymentMethod.value
          }
      )
        putCall(
          `admin/store/order/update-payment-info/${id}`,
            {
            ...state.paymentInformation
            }
        ).then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Payment Info Updated Successfully`,
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
        });
    }

    return {
      getCountries,
        handleCity,
        handleCountry,
        returnPaymentDate,
        handleShippingPerson,
        PAYMENT_METHODS,
        udpatePaymentInformation,
        handlePaymentInformation,
        getAllPaymentStatus,
        handlePaymentMethod,
        handlePaymentStatus,
        handleProvince,
        state,
        getProvince,
        getCities
    }
}